import { useState, useRef, useCallback, useEffect } from 'react'
import dashboardService from '../services/dashboards'

import './dashboard.scss'

const FrameWrapper = ({url, height}) => {
    //const [height, setHeight] = useState("1784px")
    // const measuredRef = useCallback(node => {
    //     //console.log(node)
    //     if (node !== null) {
    //         //doesn't do anything right now
    //         setHeight(node.getBoundingClientRect().height)
    //     }
    // }, [])

    return(
        <iframe
            //ref={measuredRef}
            title="iframe"
            src={url}
            width="100%"
            height={height + "px"}
            frameBorder="0"
        ></iframe>
    )
}

const Embeds = ({ embeds }) => {
    return(
        <>
        {embeds.map((embed, i) => {
            const { title, url, height } = embed
            return(
                <div key={i} className='embedSegment'>
                    <h1>{title}</h1>
                    <FrameWrapper url={url} height={height}/>                    
                </div>

            )
        })}
        </>
    )
}

const Dashboard = ({ user, dashboardId }) => {
    const [embeds, setEmbeds] = useState([])

    useEffect(() => {
        const fetchDashboard = async () => {
            const response = await dashboardService.getDashboard(dashboardId)

            if (response) {
                const embeds = response.embeds
                setEmbeds(embeds)
            }
        }
        fetchDashboard()
    }, [dashboardId])

    return (
        <div className='dashboard'>
            <Embeds embeds={embeds}/>
        </div>
    )
}

Dashboard.displayName = 'Dashboard'

export default Dashboard