import { useState , useEffect } from 'react'

import LoginForm from './components/LoginForm'
import Dashboard from './components/Dashboard'
import DashboardList from './components/DashboardList'
import Header from './components/Header'
import Admin from './components/Admin'

import loginService from './services/login'
import userService from './services/user'

import "./sassStyles/_global.scss"

const App = () => {
  const [user, setUser] = useState(null)
  const [dashboardToRender, setDashboardToRender] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userFromStorage = await userService.getUser()
        if (userFromStorage) {
          const user = userFromStorage
          setUser(user)
        }
      } catch (error) {
        setUser(null)
        setDashboardToRender(null)
        userService.clearUser()
      }
    }
    fetchUser()
  }, [])

  const selectDashboard = (id) => {
    setDashboardToRender(id)
  }

  const resetDashboardSelection = () => setDashboardToRender(null)
  
  const login = async (username, password) => {
    try {
      const user = await loginService.login({
      username, password
      })
      setUser(user)
      userService.setUser(user)
      setErrorMessage(null)
    } catch (error) {
      setErrorMessage('Wrong username or password')
    }
  }

  const logout = async () => {
    setUser(null)
    setDashboardToRender(null)
    userService.clearUser()
    try {
      await loginService.logout()
    } catch (error) {
      console.log('logout eror')
    }
  }

  if (!user) {
    return(
      <>
        <Header />
        <LoginForm onLogin={login} error={errorMessage}/>
      </>
    )
  } else if (user.username === "admin") {
    return(
      <>
        <Header user={user} onLogout={logout}/>
        <Admin user={user} onLogout={logout}/>
      </>
    )
  } else {
    if (user.dashboards.length === 1 && !dashboardToRender) selectDashboard(user.dashboards[0])

    return (
      <>
        <Header user={user} onLogout={logout} onDashboardReset={resetDashboardSelection} dashboardSelector={(dashboardToRender&&user.dashboards.length>1)?true:false}/>
        {
        dashboardToRender 
        ? <Dashboard user={user} dashboardId={dashboardToRender}/>
        : <DashboardList dashboardIds={user.dashboards} onSelect={selectDashboard}/>
        }
      </>
    )
  }
  

}

export default App;
