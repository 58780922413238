import axios from 'axios'
const baseUrl = '/api/login'

let token = null

const STORAGE_KEY = 'loggedDashboardUser'

const setUser = async user => {
  window.localStorage.setItem(
      STORAGE_KEY, JSON.stringify(user)
  )
  token = user.token
}

const updateUserSession = async () => {
  const response = await axios.post(`${baseUrl}/update`, {}, { headers: { Authorization: `bearer ${token}`}})
  return response.data
}

const getUser = async () => {
  const loggedUserJSON = window.localStorage.getItem(STORAGE_KEY)
  if (loggedUserJSON) {
    const user = JSON.parse(loggedUserJSON)
    token = user.token
    const response = await updateUserSession()
    return user
  }

  return null
}

const clearUser = () => {
  localStorage.clear()
  token = null
}

const getToken = () => token

export default {
  setUser, getUser, clearUser, getToken
}