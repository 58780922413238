import { useState } from 'react'
import './loginForm.scss'

import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'

const LoginForm = ({ onLogin, error}) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    onLogin(username, password)
    //setUsername('')
    //setPassword('')
  }

  const handleToggle = () => {
    setShowPassword(showPassword ? false : true)
  }

  return(
  <div className='loginForm'>
    
    <img className='logo' src='/dashboardlogo.png' alt='dashboard logo'></img>
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className='inputItem'>
        <label htmlFor="username">Username</label>
        <div className='inputField'>
          <input 
            autoComplete='off'
            type="text" 
            id="username" 
            value={username}
            onChange={({target}) => setUsername(target.value)}
          />
        </div>
      </div>

      <div className='inputItem'>
        <label htmlFor="password">Password</label>
        <div className='inputField'>
          <i className={showPassword ? 'visible' : 'hidden'} onClick={handleToggle}>
            {showPassword ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
          </i>
          <input
            autoComplete='off'
            type={showPassword ? "text" : "password"}
            id='password'
            value={password}
            onChange={({target}) => setPassword(target.value)}        
          />
        </div>
      </div>
      { error
      ? <p className='errorMessage'>{error}</p> 
      : null }
      <button type='submit'>Log in</button>
    </form>     
  </div>
   
  )
}

LoginForm.displayName = 'Login Form'

export default LoginForm