import dashboardService from '../services/dashboards'
import { useState , useEffect } from 'react'

import './dashboard.scss'

const DashboardList = ({ dashboardIds, onSelect }) => {
    const [dashboards, setDashboards] = useState([])
    const [isLoading, setLoading] = useState(true)

    
    useEffect(() => {
        setLoading(true)
        Promise.all(
            dashboardIds.map(async id => {
                const response = await dashboardService.getDashboard(id)
                return response
            }
        )).then((dashboards) => {
            setDashboards(dashboards.map( dashboard => dashboard))
        }).finally(() => {
            setLoading(false)
        })
    }, [dashboardIds])

    const handleClick = (id) => {
        onSelect(id)
    }

    return(
        <div className='dashboard'>
            <h2>Menu</h2>
            <div className='dashboardList'>
                {isLoading
                    ? <p>Loading...</p>
                    : dashboards.map(dashboard => <button key={dashboard.id} onClick={() => handleClick(dashboard.id)}>{dashboard.name}</button>)
                }
            </div>

        </div>
    )
  }

DashboardList.displayName = 'Dashboard List'

export default DashboardList