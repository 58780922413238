import './header.scss'

const Header = ({user, onLogout=null, dashboardSelector=false, onDashboardReset}) => {
    if (onLogout) {
        return(
            <header>
                <img className='headerLogo' src='/brandlogo.png' alt='fieldfactors logo'/>
                <img className='headerLogoMobile' src='/brandlogomobile.png' alt='fieldfactors logo'/>  
                {dashboardSelector
                    ? <button className='linkButton' onClick={onDashboardReset}>back to main menu</button>
                    : null}
                <button className='logoutButton' onClick={onLogout}>log out</button> 
            </header>
        )
    } else return (
        <header>
            <img className='headerLogo' src='/brandlogo.png' alt='fieldfactors logo'/>
            <img className='headerLogoMobile' src='/brandlogomobile.png' alt='fieldfactors logo'/>
        </header>
    )
}

Header.displayName = 'Header'

export default Header