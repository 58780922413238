import simpleRestProvider from 'ra-data-simple-rest'
import userService from '../services/user'
import { Admin, Layout, Resource, Datagrid, List, fetchUtils } from 'react-admin'
import { ArrayField, TextField, ReferenceArrayField, UrlField, ChipField, SingleFieldList, NumberField } from 'react-admin'
import { ArrayInput, NumberInput, TextInput, ReferenceArrayInput, SelectArrayInput, CheckboxGroupInput} from 'react-admin'
import { Edit, Create, SimpleForm, AppBar, SimpleFormIterator } from 'react-admin'

//to do: fix the weird chips
const UserList = () => (
    <List pagination={null}>
        <Datagrid rowClick="edit" >
            <TextField source="username" />
            <TextField source="name" /> 
            <ReferenceArrayField reference='dashboards' source="dashboards">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>        
        </Datagrid>
    </List>
)

const UserEdit = () => (
    <Edit redirect="list">
        <SimpleForm sx={{ width: '90%' }}>
            <h2>Edit User</h2>
            <TextInput source="name" />
            <TextInput source="password" />
            <ReferenceArrayInput source='dashboards' reference='dashboards'>
                <SelectArrayInput optionText="name"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
)

const UserCreate = () => (
    <Create redirect="list">
        <SimpleForm sx={{ width: '90%' }}>
            <h2>Create User</h2>
            <TextInput source="username" />
            <TextInput source="password" />
            <TextInput source="name" />
            <ReferenceArrayInput source='dashboards' reference='dashboards'>
                <CheckboxGroupInput optionText="name"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
)

const DashboardList = () => (
    <List redirect="list" pagination={null}>
        <Datagrid rowClick="edit" expandSingle>
            <TextField source="name" />
            <ArrayField source="embeds">
                <Datagrid bulkActionButtons={false} sx={{
                    '& .column-title': {width: '25%'}
                }}>
                    <TextField source='title' sx={{width:150}} />
                    <UrlField source="url"/>
                    <NumberField source="height"/>
                </Datagrid>
            </ArrayField>
            <ReferenceArrayField reference='users' source="users">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
)


const DashboardEdit = () => (
    <Edit redirect="list">
        <SimpleForm sx={{ width: '90%' }}>
            <h2>Edit Dashboard</h2>
            <h3>Dashboard name</h3>
            <TextInput source="name" />

            <h3>Dashboard users</h3>
            <ReferenceArrayInput source='users' reference='users'>
                <SelectArrayInput optionText="name"/>
            </ReferenceArrayInput>

            <h3>iFrame embeds</h3>
            <ArrayInput source='embeds'>
                <SimpleFormIterator>
                    <TextInput source='title' />
                    <TextInput source='url' type='url' fullWidth/>
                    <NumberInput source='height'/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
)


const DashboardCreate = () => (
    <Create redirect="list">
        <SimpleForm sx={{ width: '90%' }}>
            <h2>Create Dashboard</h2>
            <h3>Dashboard name</h3>
            <TextInput source="name" />

            <h3>Dashboard users</h3>
            <ReferenceArrayInput source='users' reference='users'>
                <SelectArrayInput optionText="name"/>
            </ReferenceArrayInput>

            <h3>iFrame embeds</h3>
            <ArrayInput source='embeds'>
                <SimpleFormIterator>
                    <TextInput source='title' />
                    <TextInput source='url' type='url' fullWidth/>
                    <NumberInput source='height'/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
)

const AdminScreen = () => {
    //const [screen, setScreen] = useState('overview')

    const fetchJson = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json'})
        }
        options.headers.set('Authorization', `bearer ${userService.getToken()}`)
        return fetchUtils.fetchJson(url, options)
    }

    const dataProvider = simpleRestProvider('/api', fetchJson)

    const MyAppBar = props => <AppBar {...props} sx={{display:'none'}}/>
    const MyLayout = (props) => <Layout {...props} appBar={MyAppBar}/>

    return(
        <Admin dataProvider={dataProvider} layout={MyLayout}>
            <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
            <Resource name="dashboards" list={DashboardList} edit={DashboardEdit} create={DashboardCreate}/>
        </Admin>
    )
}



AdminScreen.displayName = 'AdminScreen'

export default AdminScreen