import axios from 'axios'
import userService from './user'

const baseUrl = '/api/dashboards'

const config = () => {
    return {
        headers: {
            Authorization: `bearer ${userService.getToken()}`
        },
    }
}

const getDashboard = (id) => {
    const request = axios.get(`${baseUrl}/${id}`, config())
    return request.then(response => response.data)
}

export default { getDashboard }